import { graphql, Link } from "gatsby"

import React from "react"
import Cards from "../components/Cards"
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"

const Gallery = ({ data }) => {
  return (
    <Layout>
      <SiteMetadata title="Gallery" description="SSB Photography" />

      <div className="bg-gray-100 py-12 lg:py-16">
        {data?.portfolio?.nodes?.length > 0 ? (
          <Cards items={data.portfolio.nodes} />
        ) : (
          <div className="container">Under construction. Check back later.</div>
        )}
      </div>

      <div className="text-center mx-auto my-20">
        <Link
          to="/contact"
          className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-4 px-6 border border-blue-500 hover:border-transparent rounded-full">
          Request a session
        </Link>
      </div>
    </Layout>
  )
}

export default Gallery

export const site = graphql`
  query GalleryQuery {
    portfolio: allContentfulPortfolio(filter: {includeAsPortfolio: {eq: true}}) {
      nodes {
        ...PortfolioCard
      }
    }
  }
`
